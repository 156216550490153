import React from 'react';
import CallToAction from './CallToAction';

function HeroSection() {
  return (
    <section className="bg-gradient-to-r from-primary to-secondary text-white py-20">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-4xl md:text-6xl font-bold leading-tight mb-4">
              Reduce E-commerce Returns with ReturnsIQ
            </h1>
            <p className="text-xl mb-8 opacity-80">
              AI-powered solutions to predict and prevent returns, enhancing customer satisfaction and boosting your bottom line.
            </p>
            <CallToAction />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;