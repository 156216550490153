import React from 'react';
import { BoltIcon, ChatBubbleBottomCenterTextIcon, BanknotesIcon, BellIcon } from '@heroicons/react/24/outline';

function FeaturesSection() {
  const features = [
    {
      title: "ML-Powered Predictions",
      description: "Leverage advanced machine learning algorithms to predict return likelihood based on cart contents, purchase history, and item data.",
      icon: BoltIcon,
    },
    {
      title: "Proactive Customer Engagement",
      description: "Engage customers to help them find the right size, reducing bracketing and improving satisfaction.",
      icon: ChatBubbleBottomCenterTextIcon,
    },
    {
      title: "Smart Incentives",
      description: "Offer targeted incentives to encourage single-size purchases, optimizing inventory and reducing return rates.",
      icon: BanknotesIcon,
    },
    {
      title: "Post-Purchase Communication",
      description: "Provide timely, relevant product information after purchase to set accurate expectations and reduce returns.",
      icon: BellIcon,
    }
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">How ReturnsIQ Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex items-start">
              <feature.icon className="w-8 h-8 text-blue-500 mr-4 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;