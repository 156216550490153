import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import ProductShowcase from './components/ProductShowcase';
import SocialProof from './components/SocialProof';
import ActionableInsights from './components/ActionableInsights';
import SurveyMethods from './components/SurveyMethods';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={
              <>
                <HeroSection />
                <FeaturesSection />
              </>
            } />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;