import React from 'react';

function CallToAction() {
  const handleBookDemo = () => {
    window.open('https://cal.com/orrez/returnsiq-demo?month=2024-08&date=2024-08-20', '_blank');
  };

  return (
    <div className="inline-flex items-center space-x-4">
      <button 
        className="bg-white text-primary font-semibold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300"
        onClick={handleBookDemo}
      >
        Book a demo
      </button>
    </div>
  );
}

export default CallToAction;